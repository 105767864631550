import React from "react";
import {
  ActionButton,
  ButtonSkip,
  ButtonDownload,
  ModalComponentWrapper,
  TitleDownload,
} from "./styled";


const ModalDownloadImport = ({ onOk, onCancel, visibleModal}) => {

  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      className='modal__import'
    >
      <TitleDownload>ステップ④でのインポート用最新版をダウンロードしますか。<br/> ダウンロードしない場合はスキップしてください。</TitleDownload>
      <ActionButton>
        <ButtonDownload onClick={onOk}>ダウンロード</ButtonDownload>
        <ButtonSkip onClick={onCancel}>スキップ</ButtonSkip>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalDownloadImport;
