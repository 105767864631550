import React, { useEffect, useState } from "react";
import { ModalComponentWrapper } from "./styled";
import { Table, Tabs } from "antd";
import { formatDate, getLabelByValue } from "Utils/convertValue";
import { TableContainer } from "Layouts/Admin/styled";
import { getInvalidCourseAdmin, getInvalidCoursedependenciesAdmin, getListInValidSchool, getListInValidSeihoku } from "Apis/admin";
import { Notification } from "Components";
import { optionsYesNo, textNoData } from "Utils/data-default";

const ModalDetailDataImport = ({
  onOk,
  onCancel,
  visibleModal,
}) => {
  const [step,setStep]=useState('1')
  const [loaddingPage, setLoaddingPage] = useState(true);
  const [dataStep1, setDataStep1] = useState([]);
  const [dataStep2,setDataStep2] = useState([]);
  const [dataStep3,setDataStep3] = useState([]);
  const [dataStep4,setDataStep4] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data, result;
  
        switch (step) {
          case '1':
            if(dataStep1.length !==0) return
            data = await getInvalidCourseAdmin({ temporary: 1 });
            result = data.data;
            setDataStep1(result.items)
            break;
          case '2':
            if(dataStep2.length !==0) return
            data = await getInvalidCoursedependenciesAdmin({ temporary: 1 });
            result = data.data;
            setDataStep2(result.items)
            break;
          case '3':
            if (dataStep3.length !== 0) return
            data = await getListInValidSeihoku();
            result = data.data;
            setDataStep3(result.items)
            break;
          case '4':
            if(dataStep4.length !==0) return
            data = await getListInValidSchool({ temporary: 1 });
            result = data.data;
            setDataStep4(result.items)
            break;
          default:
            return;
        }
  
        setLoaddingPage(false);
      } catch (error) {
        setLoaddingPage(false);
        Notification.error(error.errors?.[0].msg);
      }
    };
  
    fetchData();
  }, [dataStep1.length, dataStep2.length, dataStep3.length, dataStep4.length, step]);
  const items = [
    {
      key: "1",
      label: "ステップ①",
      children: <TableDetailImport tab={step} dataSource={dataStep1} loaddingPage={loaddingPage} />,
    },
    {
      key: "2",
      label: "ステップ②",
      children: <TableDetailImport  tab={step} dataSource={dataStep2} loaddingPage={loaddingPage}/>,
    },
    {
      key: "3",
      label: "ステップ③",
      children: <TableDetailImport  tab={step} dataSource={dataStep3} loaddingPage={loaddingPage}/>,
    },
    {
      key: "4",
      label: "ステップ④",
      children: <TableDetailImport  tab={step} dataSource={dataStep4} loaddingPage={loaddingPage}/>,
    },
  ];
  const onChange = (key) => {
    setStep(key)
  };
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      style={{ height: "80vh" }}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </ModalComponentWrapper>
  );
};
function TableDetailImport({tab , dataSource ,loaddingPage}) {
  const columnsStep1 = [
    {
      title: "連番",
      render: (text, record, index) =>
        <span>
          {index + 1}
        </span>,
      key: "stt",
    },
    {
      title: "エラー説明",
      dataIndex: "reason",
      key: "reason",
      className:'min-150'
    },
    {
      title: "シリーズ名",
      dataIndex: "series_name",
      key: "series_name",
      render: (course_name) => (
        <span className="course__name">{course_name}</span>
      )
    },
    {
      title: "分類名",
      dataIndex: "classification_name",
      key: "classification_name",
      className:'min-150'
    },
    {
      title: "科目",
      dataIndex: "subject_name",
      key: "subject_name",
    },
    {
      title: "映・添",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "内容",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "講座名",
      dataIndex: "course_name",
      key: "course_name",
      render: (course_name) => (
        <span className="course__name">{course_name}</span>
      ),
    },
    {
      title: "答案添削",
      dataIndex: "edited",
      key: "edited",
      className: "data__edited",
    },
    {
      title: "総講数",
      dataIndex: "number_of_periods",
      key: "number_of_periods",
    },
    {
      title: "スタートレベル",
      dataIndex: "from_level",
      key: "from_level",
    },
    {
      title: "エンドレベル",
      dataIndex: "to_level",
      key: "to_level",
    },
    {
      title: "受験分類",
      dataIndex: "exam",
      key: "exam",
    },
    {
      title: "バージョン",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "インポート日付",
      dataIndex: "create_at",
      key: "create_at",
      className:'min-150',
      render: (create_at) => (
        <span >{formatDate(create_at)}</span>
      ),
    },
  ];
  const columnsStep2 = [
    {
      title: "連番",
      render: (text, record, index) => (
        <span>
          {index + 1}
        </span>
        ),
      key: "stt",
    },
    {
      title: "エラー説明",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: " シリーズ名",
      dataIndex: "series_name",
      key: "series_name",
      className: "min-150",
    },
    {
      title: "分類名",
      dataIndex: "classification_name",
      key: "classification_name",
      className: "min-150",
    },
    {
      title: "科目",
      dataIndex: "subject_name",
      key: "subject_name",
    },
    {
      title: "映・添",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "内容",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "講座名",
      dataIndex: "course_name",
      key: "course_name",
      render: (value) => <span className="course__name">{value}</span>,
    },
    {
      title: "答案添削",
      dataIndex: "edited",
      key: "edited",
      render: (value) => <span >{value}</span>,
    },
    {
      title: "総講数",
      dataIndex: "number_of_periods",
      key: "number_of_periods",
    },
    {
      title: "スタートレベル",
      dataIndex: "from_level",
      key: "from_level",
      className: "min-150",
    },
    {
      title: "エンドレベル",
      dataIndex: "to_level",
      key: "to_level",
      className: "min-150",
    },
    {
      title: "提案順序",
      dataIndex: "index_course",
      key: "index_course",
    },
    {
      title: "バージョン",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "インポート日付",
      dataIndex: "create_at",
      key: "create_at",
      className: "min-150",
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];
  const columnsStep3 = [
    {
      title: "連番",
      render: (text, record, index) => (
        <span>
          {index + 1}
        </span>
        ),
      key: "stt",
    },
    {
      title: "エラー説明",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "コード",
      dataIndex: "aspiration_id",
      key: "aspiration_id",
    },
    {
      title: "大学名",
      dataIndex: "university_name",
      key: "university_name",
    },
    {
      title: "学部名（正式）",
      dataIndex: "faculty_name",
      key: "faculty_name",
    },
    {
      title: "学科・専攻等名称（正式）",
      dataIndex: "department_name",
      key: "department_name",
      className:'min-150'
    },
    {
      title: "日程・方式等（正式）",
      dataIndex: "schedule_method",
      key: "schedule_method",
    },
    {
      title: "枝名",
      dataIndex: "branchname",
      key: "branchname",
    },

    {
      title: " バージョン",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "インポート日付",
      dataIndex: "created_at",
      key: "created_at",
      className:'min-150',
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];
  const columnsStep4 = [
    {
      title: "連番",
      render: (text, record, index) => (
        <span>
          {index + 1}
        </span>
        ),
      key: "stt",
    },
    {
      title: "エラー説明",
      dataIndex: "REASON",
      key: "REASON",
    },
    {
      title: "大学コード",
      dataIndex: "大学コード",
      key: "大学コード",
    },
    {
      title: "大学名",
      dataIndex: "大学名",
      key: "大学名",
    },
    {
      title: "グループ",
      dataIndex: "グループ",
      key: "グループ",
    },
    {
      title: "医学部",
      dataIndex: "医学部",
      key: "医学部",
      render: (value) => <span>
        {getLabelByValue(optionsYesNo,String(value))}
      </span>
    },
    {
      title: "バージョン",
      dataIndex: "Version",
      key: "Version",
    },
    {
      title: "インポート日付",
      dataIndex: "IMPORT_AT",
      key: "IMPORT_AT",
      className:'min-150',
      render: (created_at) => <span>{formatDate(created_at)}</span>,
    },
  ];
  const columns = {
    '1': columnsStep1,
    '2': columnsStep2,
    '3': columnsStep3,
    '4': columnsStep4,
  }
  return (
    <>
      <TableContainer>
        <Table
          columns={columns[tab]}
          dataSource={dataSource}
          loading={loaddingPage}
          pagination={false}
          scroll={{ y: '60vh', x: true }}
          locale={{
            emptyText: textNoData,
          }}
        />
      </TableContainer>
    </>
  );
}
export default ModalDetailDataImport;
