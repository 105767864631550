import ModalComponent from "Components/Modal";
import styled from "styled-components";

export const ModalComponentWrapper = styled(ModalComponent)`
  width: 500px !important;
  .ant-modal-body {
  }
  .select__version {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    .ant-select-selector {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-placeholder{
      font-size: 14px;
    }
    .ant-select-clear{
      display: flex;
    }
    

    
  }
  .ant-modal-close {
      background-color: #ef6464 !important;
      .ant-modal-close-x{
        color: white;
      }
  }
`;
export const TitleModal = styled.p`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;
export const TitleInput = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`
export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    height: 40px;
    min-width: 100px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 2rem;
    color: #ffffff;
    font-family: "Public Sans";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    margin: 0 10px;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
      height: 30px;
      line-height: 10px;
    }
  }
`;
export const ButtonOk = styled.button`
  background-color: #4994ff;
  :disabled{
    background-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`;
export const ButtonCancel = styled.button`
  background-color: #ef6464;
`;

