import ModalComponent from "Components/Modal";
import styled from "styled-components";
import { Col } from "antd";

export const ModalComponentWrapper = styled(ModalComponent)`
  width: 80% !important;

  .ant-modal-body {
    .ant-row {
      margin-top: 10px;
    }
    .footer_btn {
      margin-top: 20px;
      .ant-col {
        display: flex;
        justify-content: center;
      }
      .ant-col:first-child {
        button {
          margin-left: 200px;
        }
      }
      .ant-col:last-child {
        button {
          margin-right: 200px;
        }
      }
    }
  }
  .ant-table-wrapper .ant-table-expanded-row-fixed{
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  .ant-modal-content{
    height: 100%;
  }
`;

export const HeaderModal = styled(Col)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
  }

  .header_modal {
    display: grid;
  }

  .info_title {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .info_data {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const TableDetail = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableRow = styled.tr`
  border: 1px solid black;
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid black;
`;
export const TitleInfo = styled.div`
  width: max-content;
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-right: 10px;
`
export const ValueInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`

