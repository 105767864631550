import React, { useState } from "react";
import {
  ActionButton,
  ButtonCancel,
  ButtonOk,
  ModalComponentWrapper,
  TitleInput,
  TitleModal,
} from "./styled";
import { Input } from "antd";
import { regex } from "Utils/regex";


const ModalVersionName = ({ onOk, onCancel, visibleModal  }) => {
  const [versionName, setVersionName] = useState('')
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (!regex.LettersNumberAndFewSpecialCharacters.test(e.key)
      && keyCode !== 8
      && keyCode !== 46) {
      e.preventDefault();
    }
  };

  const handleInput = (e) => {
    let text = e.target.value;
    if (text.length > 20) {
      text = text.substring(0, 20);
    }
    setVersionName(text.replace(regex.negationLettersAndNumber, ''));
  }
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={()=>onOk(versionName)}
      onCancel={onCancel}
      centered
      className='modal__import'
    >
      <TitleModal>バージョン名設定</TitleModal>
      
      <TitleInput>バージョン名</TitleInput>
      <Input maxLength={20} value={versionName} onInput={(e) => handleInput(e)} onKeyDown={handleKeyDown}/>
      <ActionButton>
        <ButtonOk disabled={versionName.length === 0} onClick={()=>onOk(versionName)}>
          保存
        </ButtonOk>
        <ButtonCancel onClick={onCancel}>キャンセル</ButtonCancel>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalVersionName;
