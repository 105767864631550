import ModalComponent from "Components/Modal";
import styled from "styled-components";
import { Col } from "antd";

export const ModalComponentWrapper = styled(ModalComponent)`
  width: 500px !important;
  .ant-modal-body {
  }
  .select__version {
    height: 40px;
    width: 100%;
    .ant-select-selector {
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #4994FF;
      color: white;
    }
    .ant-select-selection-placeholder{
      color: white;
      font-size: 14px;
    }
    .ant-select-arrow{
      color: white;
    }
    .ant-select-clear{
      display: flex;
      background-color: #4994FF;
    }
    

    
  }
  .ant-modal-close {
      background-color: #ef6464 !important;
      .ant-modal-close-x{
        color: white;
      }
  }
`;
export const Step = styled.p`
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;
export const OtionImport = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  .option__import {
    display: flex;
    justify-content: space-between;
    label {
      span {
        font-size: 18px;
      }
    }
  }
`;
export const HeaderModal = styled(Col)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
  }

  .header_modal {
    display: grid;
  }

  .info_title {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .info_data {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    height: 40px;
    min-width: 100px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 2rem;
    color: #ffffff;
    font-family: "Public Sans";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    margin: 0 10px;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
      height: 30px;
      line-height: 10px;
    }
  }
`;
export const ButtonImport = styled.button`
  background-color: #4994ff;
`;
export const ButtonCancel = styled.button`
  background-color: #ef6464;
`;
export const ActionUpload = styled.label`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #4994FF;
  border: none;
  padding: 0 10px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 10px;
    height: 30px;
    line-height: 10px;
  }
  input[type="file"] {
    display: none;
  }

  .label-wrapper:hover {
    background-color: #1976d2;
  }

  .label-wrapper input[type="file"] {
    display: none;
  }

  .label-wrapper span {
    margin-left: 10px;
  }
`;
