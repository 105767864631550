import React, { useEffect, useState } from "react";
import {
  ActionButton,
  ButtonCancel,
  ButtonDownload,
  ModalComponentWrapper,
  TitleDownload,
  TitleSelect,
} from "./styled";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "Store/Actions";
import LoadingModal from "Pages/LoadingModal";
import { exportExcelRaw } from "Utils/export-excel-raw";
import { dataStep, textNoData } from "Utils/data-default";

const {
  getVersionImport
} = actions;
const ModalExportExcel = ({ onOk, onCancel, visibleModal  }) => {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const dispatch = useDispatch();
  const [version, setVersion] = useState();
  const [step, setStep] = useState();

  const { dataVersionImport } = useSelector((state) => state.admin);

  
  useEffect(() => {
    dispatch(
      getVersionImport(
        {
          total: 0,
        },
        (action, res) => {}
      )
    );
  }, [dispatch])



  const handleCancel = () => {
    setLoadingDownload(false);
  };
  const onSelectVersion =  (value) => {
    setVersion(value)
  };
  const onSelectStep =  (value) => {
    setStep(value)
  };
  const handleDownload = async () => {
    setLoadingDownload(true)
    let obj = {
      version: version,
      step : step ,
    };
    await exportExcelRaw(obj);
    setLoadingDownload(false)
    
  }
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      className='modal__import'
    >
      <TitleDownload>最新版ダウンロード</TitleDownload>
      
        <TitleSelect>バージョン</TitleSelect>
        <Select
          className="select__version"
          showSearch
          placeholder="バージョンを選択してください。"
          optionFilterProp="children"
          allowClear
          onChange={onSelectVersion}
          popupClassName="popupVersion"
          getPopupContainer={(trigger) => trigger.parentElement}
          options={dataVersionImport}
          notFoundContent={textNoData}
      />
      
      <TitleSelect>ステップ</TitleSelect>
      <Select
          className="select__version step"
          showSearch
          placeholder="ステップを選択してください。"
          optionFilterProp="children"
          allowClear
          onChange={onSelectStep}
          popupClassName="popupVersion"
          getPopupContainer={(trigger) => trigger.parentElement}
          options={dataStep}
          notFoundContent={textNoData}
        />
      <ActionButton>
        <ButtonDownload
          disabled={!version || !step}
          onClick={handleDownload}
        >
          ダウンロード
        </ButtonDownload>
        <ButtonCancel onClick={onCancel}>キャンセル</ButtonCancel>
      </ActionButton>
      {( loadingDownload) && (
        <LoadingModal textLoading={""} cancelEvent={handleCancel} />
      )}
    </ModalComponentWrapper>
  );
};

export default ModalExportExcel;
