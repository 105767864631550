import React, { useEffect, useState } from "react";
import {
  ActionButton,
  ActionUpload,
  ButtonCancel,
  ButtonImport,
  ModalComponentWrapper,
  OtionImport,
  Step,
} from "./styled";
import { Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from "Constants";
import { Notification } from "Components";
import actions from "Store/Actions";
import LoadingModal from "Pages/LoadingModal";
import { StepNumber, Steps } from "Utils/data-default";
import { importStepVersion } from "Apis/admin";
const {
  importListCourse,
  importCourseDependencies,
  importSeihoku,
  importSchoolGroup,
  getVersionImport
} = actions;
const ModalImport = ({ onOk, onCancel, visibleModal, stepImport, resetData, version }) => {
  const [value, setValue] = useState("file");
  const [nameFile, setNameFile] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [loadingImport, setLoadingImport] = useState(false);
  const dispatch = useDispatch();
  const [versionImport, setVersionImport] = useState();
  const { dataVersionImport, loadingVersionImport } = useSelector((state) => state.admin);
  
  
  useEffect(() => {
    if (dataVersionImport.length === 0  && value === 'version' ) { 
      dispatch(getVersionImport({
        total: 0
      },(action, res) => {}));
    }
  },[dataVersionImport.length, dispatch, value])
  const onChange = (e) => {
    setValue(e.target.value);
  };
  function handleFileChange(event, name) {
    const file = event.target.files[0];
    const fileName = file.name;
    const getName = fileName.replace(".xlsx", "");
    setFile(file);
    setFileName(fileName);
    setNameFile(getName);
  }
  function importExcel() {
    if (!fileName || !file) return;
  
    if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
      Notification.error("Excelファイル拡張子が正しくありません。");
      return;
    }
  
    setLoadingImport(true);
  
    const importCallback = (action, res) => {
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setLoadingImport(false);
        Notification.success("ファイルをインポートしました。");
        resetData()
      } else {
        handleCancel();
      }
      onCancel();
    };
  
    switch (stepImport) {
      case "step1":
        dispatch(importListCourse({ list_course_file: file }, importCallback));
        break;
      case "step2":
        dispatch(importCourseDependencies({ set_course_file: file }, importCallback));
        break;
      case "step3":
        dispatch(importSeihoku({ seihoku_file: file }, importCallback));
        break;
      case "step4":
        dispatch(importSchoolGroup({ university_group_file: file }, importCallback));
        break;
      default:
        Notification.error("Invalid stepImport value.");
    }
  }
  const importVersion = async () => {
    if(!versionImport) return
    try {
      setLoadingImport(true);
      const data = await importStepVersion({
        step: StepNumber[stepImport],
        version: versionImport,
        new_version: version
      })
      if (data.status === 200) {
        Notification.success("ファイルをインポートしました。");
        resetData()
      }
    } catch (error) {
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          error.message ||
          error.errors?.[0].msg
      )
    }
    onCancel();
  }
  const handleCancel = () => {
    // setNameFile()
    setLoadingImport(false);
  };
  const onSelectVersion =  (value) => {
    setVersionImport(value)
  };


  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      className='modal__import'
    >
      <Step> {stepImport && Steps[stepImport]} </Step>
      <OtionImport>
        <Radio.Group
          className="option__import"
          onChange={onChange}
          value={value}
        >
          <Radio value={"file"}>データインポート</Radio>
          <Radio value={"version"}>データ複製</Radio>
        </Radio.Group>
      </OtionImport>
      {value && value === "file" && (
        <ActionUpload>
          <span>{nameFile || "エクセルファイルを選択"}</span>
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={(e) => handleFileChange(e, "schoolGroupImport")}
            onClick={(e) => (e.target.value = null)}
          />
        </ActionUpload>
      )}
      {value && value === "version" && (
        <Select
          className="select__version"
          showSearch
          placeholder="バージョンを選択"
          optionFilterProp="children"
          allowClear
          onChange={onSelectVersion}
          popupClassName="popupVersion"
          getPopupContainer={(trigger) => trigger.parentElement}
          options={dataVersionImport}
        />
      )}

      <ActionButton>
        {value && value === "file" && <ButtonImport onClick={importExcel}>OK</ButtonImport>}
        {value && value === "version" && <ButtonImport onClick={importVersion}>OK</ButtonImport>}
        <ButtonCancel onClick={onCancel}>キャンセル</ButtonCancel>
      </ActionButton>
      {(loadingVersionImport || loadingImport) && (
        <LoadingModal textLoading={""} cancelEvent={handleCancel} />
      )}
    </ModalComponentWrapper>
  );
};

export default ModalImport;
